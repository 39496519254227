import React, { useEffect, useRef } from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import VerticalTimeline from './VerticalTimeline';
import HorizontalTimeline from './HorizontalTimeline';
import useSpecialityData from '../hooks/useSpecialityData';
import { handleArrayNonArrayElements } from './SpecialityTimeLine';

const SpecialityDesignationReport = ({ view, filters = {}, search, onUpdatePlacement }: any) => {
  const { getDesignationData, designation } = useSpecialityData();
  const verticalViewRef = useRef<any>();

  useEffect(() => {
    getDesignationData(search, filters);
  }, []);

  useEffect(() => {
    if (verticalViewRef.current) {
      const { clientHeight } = verticalViewRef.current;
      onUpdatePlacement?.(Math.ceil(clientHeight / 10) + 1);
    }
  }, [designation]);

  const dataByYear: any = {};
  const dataByYearGroupedByApplication: any = {};
  let applicationNumbers: string[] = [];

  designation.forEach((obj: any) => {
    obj.specialty_designations.forEach((spec: any) => {
      const fieldToConsider =
        spec.designation_date ||
        spec.aa_date ||
        spec.bt_date ||
        spec.ft_date ||
        spec.designated_date ||
        spec.rescinded_date ||
        spec.final_approval_date;

      if (fieldToConsider) {
        const year = fieldToConsider.substr(0, 4);
        dataByYear[`${year}`] = dataByYear[`${year}`] || {
          aa: {},
          bt: {},
          ft: {},
          or: {}
        };
        dataByYearGroupedByApplication[`${year}`] = dataByYearGroupedByApplication[`${year}`] || {};
        applicationNumbers.push(obj.application_number);
        const mainCardData = {
          tradeName: handleArrayNonArrayElements(obj.trade_name ?? ''),
          activeIngredients: handleArrayNonArrayElements(obj.active_ingredients ?? ''),
          number: obj.application_number,
          indications: obj.indication || '',
          sponsorName: obj.sponsor_name,
          route: handleArrayNonArrayElements(obj.route || ''),
          marketingStatus: obj.marketing_status || '',
          formulation: handleArrayNonArrayElements(obj.formulation || ''),
          applicationType: obj.application_type || ''
        };
        const specialityDesignationHorizontal = {
          ...mainCardData,
          specialityDesignation: [{ ...spec }]
        };
        const specialityDesignationVertical = {
          ...mainCardData,
          specialityDesignation: [{ ...spec }]
        };

        // @ts-ignore
        if (dataByYear[`${year}`][`${spec?.type}`][`${obj.application_number}`]) {
          // @ts-ignore
          dataByYear[`${year}`][`${spec?.type}`][
            `${obj.application_number}`
          ].specialityDesignation.push(spec);
        } else {
          // @ts-ignore
          dataByYear[`${year}`][`${spec?.type}`][`${obj.application_number}`] =
            specialityDesignationVertical;
        }

        if (dataByYearGroupedByApplication[`${year}`][`${obj.application_number}`]) {
          dataByYearGroupedByApplication[`${year}`][
            `${obj.application_number}`
          ].specialityDesignation.push(spec);
        } else {
          dataByYearGroupedByApplication[`${year}`][`${obj.application_number}`] =
            specialityDesignationHorizontal;
        }
      }
    });
  });

  if (applicationNumbers) {
    // @ts-ignore
    applicationNumbers = [...new Set(applicationNumbers)];
  }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {view === 'horizontal' ? (
        <Stack direction='row' justifyContent='space-between'>
          <HorizontalTimeline
            dataByYear={dataByYearGroupedByApplication}
            applicationNumbers={applicationNumbers}
          />
        </Stack>
      ) : (
        <Grid container mb={3} ref={verticalViewRef}>
          <VerticalTimeline dataByYear={dataByYear} viewMode />
        </Grid>
      )}
    </>
  );
};

export default React.memo(SpecialityDesignationReport);
