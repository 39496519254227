import { useMemo } from 'react';
import {
  Stack,
  DialogContent,
  DialogTitle,
  Box,
  IconButton,
  Typography,
  Dialog,
  Button,
  CircularProgress
} from '@mui/material';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CloseIcon } from '../../assets/svgs/Icons';
import styles from './styles/PDFComparisonDialog.style';
import { DownloadModalProps } from './interface';

const DownloadLoadingModal = ({
  modalOpen,
  closeModal,
  progressData,
  downloadError,
  setDownloadError,
  handleDownloadComparison,
  handleCancelCall
}: DownloadModalProps) => {
  const dialogContent = useMemo(() => {
    if (downloadError) {
      return 'An error has occurred in downloading. Please try again';
    }
    if (progressData.progress !== 100) {
      return 'It may take a few seconds to compile section summary.';
    }
    return 'Label Section Summary file has been downloaded successfully';
  }, [downloadError, progressData.progress]);

  return (
    <Dialog
      open={modalOpen}
      keepMounted={false}
      maxWidth='xs'
      onClose={() => {
        if (progressData.progress === 100) {
          closeModal();
        }
      }}>
      <DialogTitle id='alert-dialog-title'>
        <Stack sx={styles.loaderWrapper}>
          <Box>
            {(progressData.progress === 100 || downloadError) && (
              <IconButton aria-label='close' onClick={closeModal} sx={styles.loaderCloseIcon}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <Box
            sx={{
              position: 'relative',
              display: 'inline-flex',
              justifyContent: 'center',
              width: 200,
              height: 200
            }}>
            <CircularProgress
              variant='determinate'
              value={100}
              size={200}
              thickness={3}
              sx={{
                ...styles.circularProgress,
                ...{ color: 'gray.background' }
              }}
            />
            {!downloadError && (
              <CircularProgress
                variant={progressData.progress <= 100 ? 'determinate' : 'indeterminate'}
                value={progressData.progress}
                color='primary'
                size={200}
                thickness={3}
                sx={styles.circularProgress}
              />
            )}

            <Box sx={styles.loaderPercentWrapper}>
              {downloadError ? (
                <ErrorOutlineIcon />
              ) : (
                <Typography
                  display='flex'
                  variant='caption'
                  component='div'
                  sx={styles.percentTypography}>
                  <span>{`${Math.round(progressData.progress)}`}</span>
                  <span style={styles.percentText}>%</span>
                </Typography>
              )}
            </Box>
          </Box>
          <Typography ml={1} sx={styles.loaderStatusText}>
            {downloadError ? 'Please try again' : progressData.message}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box sx={styles.dialogContentWrapper}>
          <Typography sx={{ fontSize: '16px', color: downloadError ? 'red' : 'black' }}>
            {dialogContent}
          </Typography>

          <Button
            variant='contained'
            color='secondary'
            sx={styles.loaderButton}
            onClick={() => {
              if (downloadError) {
                setDownloadError(false);
                handleDownloadComparison();
              } else if (progressData.progress !== 100) {
                handleCancelCall();
              } else {
                closeModal();
              }
            }}>
            {downloadError && 'Try Again'}
            {!downloadError && (progressData.progress === 100 ? 'Done' : 'Cancel')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadLoadingModal;
